/*Rounded Corners*/
/*top-left rounded Corners*/
.jqx-rc-tl-material {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
}
/*top-right rounded Corners*/
.jqx-rc-tr-material {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
/*bottom-left rounded Corners*/
.jqx-rc-bl-material {
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
/*bottom-right rounded Corners*/
.jqx-rc-br-material {
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
/*top rounded Corners*/
.jqx-rc-t-material {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}
/*bottom rounded Corners*/
.jqx-rc-b-material {
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
/*right rounded Corners*/
.jqx-rc-r-material {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
/*left rounded Corners*/
.jqx-rc-l-material {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
/*all rounded Corners*/
.jqx-rc-all-material {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.jqx-widget-material, .jqx-widget-header-material, .jqx-fill-state-normal-material,
.jqx-widget-content-material, .jqx-fill-state-hover-material, .jqx-fill-state-pressed-material {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.jqx-widget-material {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #555;
}

.jqx-widget-content-material {
      font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #555;
    background-color: #ffffff;
    border-color: #E0E0E0;
}

.jqx-widget-header-material {
    background-color: #fff;
    border-color: #E0E0E0;
    color: #757575;
    font-weight: 500;
    *zoom: 1;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #fff;
    color: #757575;
    font-size: 14px;
}


.jqx-fill-state-normal-material {
    border-color: #0077BE;
    *zoom: 1;
    background: #fafafa;
}
.jqx-widget-material input::selection, input.jqx-input-widget-material::selection, .jqx-widget-content-material input::selection {
    background: #0077BE;
    color: #fff;
}
.jqx-toolbar-material{
     border-color: #e0e0e0;
}

.jqx-button-material, jqx-button-material.jqx-fill-state-normal-material {
    color: #fff;
    background: #0077BE;
    border-color: #0077BE;
    *zoom: 1;
    outline: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 2px;
}
.jqx-button-material button, jqx-button-material input {
    background: transparent;
    color: inherit;
    border:none;
    outline: none;
}


.jqx-slider-button-material {
    padding:3px;
    background: transparent;
    border:transparent;
}
    .jqx-button-material.float {
        border-radius: 100%;
        min-height: 48px;
        min-width: 48px;
        width: 48px;
        height: 48px;
        max-height: 48px;
        max-width:48px;
    }

    .jqx-button-material.outlined {
        background: transparent;
        color: #0077BE;
        border-width: 2px;
    }

    .jqx-button-material.flat {
        background: transparent;
        color: #0077BE;
        border: none;
    }

.jqx-fill-state-hover-material {
}

.jqx-fill-state-pressed-material {
}

.jqx-fill-state-hover-material, .jqx-fill-state-focus-material {
    text-decoration: none;
}

 .jqx-expander-header.jqx-fill-state-hover-material,
 .jqx-expander-header.jqx-fill-state-normal-material,
 .jqx-expander-header.jqx-fill-state-pressed-material
 {
      background: #fff;
      border-color: #e0e0e0;
}
.jqx-expander-header.jqx-fill-state-hover-material {
    background: #F5F5F5;
}

.jqx-expander-header-material {
    padding:10px;
}
.jqx-button-material.jqx-fill-state-hover {
    opacity: 0.9;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    *zoom: 1;
}

    .jqx-button-material.jqx-fill-state-hover.outlined,
    .jqx-button-material.jqx-fill-state-hover.flat {
        color: #0077BE;
        box-shadow: none;
    }

.jqx-button-material.jqx-fill-state-pressed {
    cursor: pointer;
    background: #0077BE;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

    .jqx-button-material.jqx-fill-state-pressed.float {
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    }

    .jqx-slider-button-material.jqx-fill-state-pressed-material,
    .jqx-button-material.jqx-fill-state-pressed.outlined,
    .jqx-button-material.jqx-fill-state-pressed.flat {
        background: rgba(179,229,252,0.15);
        box-shadow: none;
        color: #0077BE;
    }

.jqx-button-material.jqx-fill-state-focus {
    background: #0069A8;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
  .jqx-slider-button-material.jqx-fill-state-focus-material {
      background: transparent;
      border-color: transparent;
      box-shadow:none;
  }

    .jqx-button-material.jqx-fill-state-focus.outlined,
    .jqx-button-material.jqx-fill-state-focus.flat {
        box-shadow: none;
        background: rgba(99,0,238,0.15);
        color: #0069A8;
    }

.jqx-dropdownlist-state-normal-material, .jqx-dropdownlist-state-hover-material, .jqx-dropdownlist-state-selected-material,
.jqx-scrollbar-button-state-hover-material, .jqx-scrollbar-button-state-normal-material, .jqx-scrollbar-button-state-pressed-material,
.jqx-scrollbar-thumb-state-normal-horizontal-material, .jqx-scrollbar-thumb-state-hover-horizontal-material, .jqx-scrollbar-thumb-state-pressed-horizontal-material,
.jqx-scrollbar-thumb-state-normal-material, .jqx-scrollbar-thumb-state-pressed-material, .jqx-tree-item-hover-material, .jqx-tree-item-selected-material,
.jqx-tree-item-material, .jqx-menu-item-material, .jqx-menu-item-hover-material, .jqx-menu-item-selected-material, .jqx-menu-item-top-material, .jqx-menu-item-top-hover-material,
.jqx-menu-item-top-selected-material, .jqx-slider-button-material, .jqx-slider-slider-material {
    -webkit-transition: background-color 100ms linear;
    -moz-transition: background-color 100ms linear;
    -o-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
}


.jqx-primary-material.jqx-input-label-material {
   color: #1ca8dd !important;
}
.jqx-primary-material.jqx-input-bar-material:before {
   background: #1ca8dd !important;
}
.jqx-success-material.jqx-input-label-material {
   color: #5cb85c !important;
}
.jqx-success-material.jqx-input-bar-material:before {
   background: #5cb85c !important;
}
.jqx-inverse-material.jqx-input-label-material {
   color: #666 !important;
}
.jqx-inverse-material.jqx-input-bar-material:before {
   background: #666 !important;
}
.jqx-danger-material.jqx-input-label-material {
   color: #d9534f !important;
}
.jqx-danger-material.jqx-input-bar-material:before {
   background: #d9534f !important;
}
.jqx-warning-material.jqx-input-label-material {
   color: #f0ad4e !important;
}
.jqx-warning-material.jqx-input-bar-material:before {
   background: #f0ad4e !important;
}
.jqx-info-material.jqx-input-label-material {
   color: #5bc0de !important;
}
.jqx-info-material.jqx-input-bar-material:before {
   background: #5bc0de !important;
}

.jqx-slider-tooltip-material.jqx-primary-slider, .jqx-slider-tooltip-material.jqx-primary-slider .jqx-fill-state-normal-material {
    border-color: #1ca8dd;
    background: #1ca8dd;
}
.jqx-slider-tooltip-material.jqx-success-slider, .jqx-slider-tooltip-material.jqx-success-slider .jqx-fill-state-normal-material {
    border-color: #5cb85c;
    background: #5cb85c;
}
.jqx-slider-tooltip-material.jqx-inverse-slider, .jqx-slider-tooltip-material.jqx-inverse-slider .jqx-fill-state-normal-material {
    border-color: #666;
    background: #666;
}
.jqx-slider-tooltip-material.jqx-danger-slider, .jqx-slider-tooltip-material.jqx-danger-slider .jqx-fill-state-normal-material {
    border-color: #d9534f;
    background: #d9534f;
}
.jqx-slider-tooltip-material.jqx-warning-slider, .jqx-slider-tooltip-material.jqx-warning-slider .jqx-fill-state-normal-material {
    border-color: #f0ad4e;
    background: #f0ad4e;
}
.jqx-slider-tooltip-material.jqx-info-slider, .jqx-slider-tooltip-material.jqx-info-slider .jqx-fill-state-normal-material {
    border-color: #5bc0de;
    background: #5bc0de;
}


.jqx-primary-material {
    color: #1ca8dd !important;
    background: #fff !important;
    border-color: #1ca8dd !important;
    text-shadow: none !important;
}

    .jqx-primary-material.jqx-dropdownlist-state-normal-material,
    .jqx-primary-material.jqx-slider-button-material,
    .jqx-primary-material.jqx-slider-slider-material,
    .jqx-primary-material.jqx-combobox-arrow-normal-material,
    .jqx-primary-material.jqx-combobox-arrow-hover-material,
    .jqx-primary-material.jqx-action-button-material,
    .jqx-primary-material:hover,
    .jqx-primary-material:focus,
    .jqx-primary-material:active,
    .jqx-primary-material.active,
    .jqx-primary-material.disabled,
    .jqx-primary-material[disabled] {
        color: #fff !important;
        background: #1ca8dd !important;
        border-color: #1ca8dd !important;
        text-shadow: none !important;
    }

    .jqx-fill-state-pressed-material.jqx-primary-material,
    .jqx-primary-material:active,
    .jqx-primary-material.active {
        color: #fff !important;
        background-color: #1ca8dd !important;
        border-color: #1ca8dd !important;
        text-shadow: none !important;
    }

.jqx-success-material {
    color: #5cb85c !important;
    background: #fff !important;
    border-color: #5cb85c !important;
    text-shadow: none !important;
}

    .jqx-success-material.jqx-dropdownlist-state-normal-material,
    .jqx-success-material.jqx-slider-button-material,
    .jqx-success-material.jqx-slider-slider-material,
    .jqx-success-material.jqx-combobox-arrow-normal-material,
    .jqx-success-material.jqx-combobox-arrow-hover-material,
    .jqx-success-material.jqx-action-button-material,
    .jqx-success-material:hover,
    .jqx-success-material:focus,
    .jqx-success-material:active,
    .jqx-success-material.active,
    .jqx-success-material.disabled,
    .jqx-success-material[disabled] {
        color: #fff !important;
        background: #5cb85c !important;
        border-color: #5cb85c !important;
        text-shadow: none !important;
    }

    .jqx-fill-state-pressed-material.jqx-success-material,
    .jqx-success-material:active,
    .jqx-success-material.active {
        text-shadow: none !important;
        color: #fff !important;
        background: #5cb85c !important;
        border-color: #5cb85c !important;
    }

.jqx-inverse-material {
    text-shadow: none !important;
    color: #666 !important;
    background: #fff !important;
    border-color: #cccccc !important;
}

    .jqx-inverse-material.jqx-dropdownlist-state-normal-material,
    .jqx-inverse-material.jqx-slider-button-material,
    .jqx-inverse-material.jqx-slider-slider-material,
    .jqx-inverse-material.jqx-combobox-arrow-hover-material,
    .jqx-inverse-material.jqx-combobox-arrow-normal-material,
    .jqx-inverse-material.jqx-action-button-material,
    .jqx-inverse-material:hover,
    .jqx-inverse-material:focus,
    .jqx-inverse-material:active,
    .jqx-inverse-material.active,
    .jqx-inverse-material.disabled,
    .jqx-inverse-material[disabled] {
        text-shadow: none !important;
        color: #666 !important;
        background: #cccccc !important;
        border-color: #cccccc !important;
    }

    .jqx-fill-state-pressed-material.jqx-inverse-material,
    .jqx-inverse-material:active,
    .jqx-inverse-material.active {
        text-shadow: none !important;
        color: #666 !important;
        background: #cccccc !important;
        border-color: #cccccc !important;
    }


.jqx-danger-material {
    text-shadow: none !important;
    color: #d9534f !important;
    background: #fff !important;
    border-color: #d9534f !important;
}

    .jqx-danger-material.jqx-dropdownlist-state-normal-material,
    .jqx-danger-material.jqx-slider-button-material,
    .jqx-danger-material.jqx-slider-slider-material,
    .jqx-danger-material.jqx-combobox-arrow-hover-material,
    .jqx-danger-material.jqx-combobox-arrow-normal-material,
    .jqx-danger-material.jqx-action-button-material,
    .jqx-danger-material:hover,
    .jqx-danger-material:focus,
    .jqx-danger-material:active,
    .jqx-danger-material.active,
    .jqx-danger-material.disabled,
    .jqx-danger-material[disabled] {
        text-shadow: none !important;
        color: #fff !important;
        background: #d9534f !important;
        border-color: #d9534f !important;
    }

    .jqx-fill-state-pressed-material.jqx-danger-material,
    .jqx-danger-material:active,
    .jqx-danger-material.active {
        text-shadow: none !important;
        color: #fff !important;
        background: #d9534f !important;
        border-color: #d9534f !important;
    }

.jqx-validator-error-label-material {
    color: #d9534f !important;
}

.jqx-warning-material {
    text-shadow: none !important;
    color: #f0ad4e !important;
    background: #fff !important;
    border-color: #f0ad4e !important;
}

    .jqx-warning-material.jqx-dropdownlist-state-normal-material,
    .jqx-warning-material.jqx-slider-button-material,
    .jqx-warning-material.jqx-slider-slider-material,
    .jqx-warning-material.jqx-combobox-arrow-hover-material,
    .jqx-warning-material.jqx-combobox-arrow-normal-material,
    .jqx-warning-material.jqx-action-button-material,
    .jqx-warning-material:hover,
    .jqx-warning-material:focus,
    .jqx-warning-material:active,
    .jqx-warning-material.active,
    .jqx-warning-material.disabled,
    .jqx-warning-material[disabled] {
        text-shadow: none !important;
        color: #fff !important;
        background: #f0ad4e !important;
        border-color: #f0ad4e !important;
    }

    .jqx-fill-state-pressed-material.jqx-warning-material,
    .jqx-warning-material:active,
    .jqx-warning-material.active {
        text-shadow: none !important;
        color: #fff !important;
        background: #f0ad4e !important;
        border-color: #f0ad4e !important;
    }


.jqx-info-material {
    text-shadow: none !important;
    color: #5bc0de !important;
    background: #fff !important;
    border-color: #5bc0de !important;
}

    .jqx-info-material.jqx-dropdownlist-state-normal-material,
    .jqx-info-material.jqx-slider-button-material,
    .jqx-info-material.jqx-slider-slider-material,
    .jqx-info-material.jqx-combobox-arrow-hover-material,
    .jqx-info-material.jqx-combobox-arrow-normal-material,
    .jqx-info-material.jqx-action-button-material,
    .jqx-info-material:hover,
    .jqx-info-material:focus,
    .jqx-info-material:active,
    .jqx-info-material.active,
    .jqx-info-material.disabled,
    .jqx-info-material[disabled] {
        color: #fff !important;
        background: #5bc0de !important;
        border-color: #5bc0de !important;
        text-shadow: none !important;
    }

    .jqx-fill-state-pressed-material.jqx-info-material,
    .jqx-info-material:active,
    .jqx-info-material.active {
        text-shadow: none !important;
        color: #fff !important;
        background: #5bc0de !important;
        border-color: #5bc0de !important;
    }

.jqx-fill-state-pressed-material {
    background-image: none;
    outline: 0;
}

.jqx-grid-group-column-material {
    border-color: transparent;
}
.jqx-grid-column-menubutton-material {
    border-width: 0px;
}
.jqx-grid-groups-row-material > span {
    padding-left: 4px;
}

.jqx-grid-cell-material {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-grid-pager-top-material .jqx-button-material,
.jqx-grid-pager-material .jqx-button-material {
    color: rgba(0,0,0,.54) !important;
    background-color: transparent;
    border-color: transparent;
    position: relative;
    height:30px !important;
    width:30px !important;
    border-radius:50%;
    top: -4px;
}

.jqx-grid-pager-input-material  {
    padding:0px !important;
}

.jqx-grid-pager-top-material .jqx-button-material > div,
.jqx-grid-pager-material .jqx-button-material > div {
    top: 3px;
    position: relative;
    left: 2px;
}

.jqx-grid-pager-top-material .jqx-button-material.jqx-fill-state-hover,
.jqx-grid-pager-top-material .jqx-button-material.jqx-fill-state-pressed,
.jqx-grid-pager-material .jqx-button-material.jqx-fill-state-hover,
.jqx-grid-pager-material .jqx-button-material.jqx-fill-state-pressed
{
    color: rgba(0,0,0,.54) !important;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.jqx-grid-pager-top-material .jqx-grid-pager-number-material,
.jqx-grid-pager-material .jqx-grid-pager-number-material {

    background-color: transparent;
    border-color: transparent;
    color: rgba(0,0,0,.54) !important;
    font-size:12px;
}

.jqx-grid-pager-top-material .jqx-grid-pager-number-material:hover,
.jqx-grid-pager-material .jqx-grid-pager-number-material:hover {
    font-size:12px;
}

.jqx-grid-pager-top-material .jqx-grid-pager-number-material.jqx-fill-state-pressed-material ,
.jqx-grid-pager-material .jqx-grid-pager-number-material.jqx-fill-state-pressed-material {
    color: #0077BE !important;
    font-weight: bold !important;
}

.jqx-grid-column-menubutton-material {
    background-color: transparent;
    border-color: #E0E0E0 !important;
}

.jqx-cell-material {
    font-size: 13px;
}

.jqx-calendar-material > div {
    padding: 10px;
    box-sizing: border-box;
}

.jqx-calendar-row-header-material, .jqx-calendar-top-left-header-material {
    background-color: #f0f0f0;
    border: 0px solid #f2f2f2;
}

.jqx-calendar-column-header-material {
    background-color: #FFF;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #e9e9e9;
}

.jqx-expander-header-material {
    padding-top: 10px;
    padding-bottom: 10px;
}

.jqx-ribbon-header-vertical-material, .jqx-widget-header-vertical-material {
    background: #fff;
}

.jqx-scrollbar-state-normal-material {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-left-color: #ddd;
}

.jqx-scrollbar-thumb-state-normal-material, .jqx-scrollbar-thumb-state-normal-horizontal-material {
    background: #f5f5f5;
    border-color: #b3b3b3;
}

.jqx-scrollbar-thumb-state-hover-material, .jqx-scrollbar-thumb-state-hover-horizontal-material {
    background: #e6e6e6;
    border-color: #b3b3b3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.jqx-progressbar-material {
    background: #f7f7f7 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-progressbar-value-material, .jqx-splitter-collapse-button-horizontal-material {
    background: #0077BE;
}

.jqx-splitter-collapse-button-vertical-material, .jqx-progressbar-value-vertical-material {
    background: #0077BE;
}


.jqx-scrollbar-thumb-state-pressed-material, .jqx-splitter-splitbar-vertical-material, .jqx-splitter-splitbar-horizontal-material, .jqx-scrollbar-thumb-state-pressed-horizontal-material,
.jqx-scrollbar-button-state-pressed-material {
    background: #d9d9d9;
    border-color: #b3b3b3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.jqx-grid-column-sortdescbutton-material, jqx-grid-column-filterbutton-material, .jqx-grid-column-sortascbutton-material {
    background-color: transparent;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-color: #E0E0E0;
}

.jqx-menu-vertical-material {
    background: #ffffff;
    filter: none;
}

.jqx-grid-bottomright-material, .jqx-panel-bottomright-material, .jqx-listbox-bottomright-material {
    background-color: #fafafa;
}

.jqx-window-material, .jqx-tooltip-material {
    box-shadow: 0 4px 23px 5px rgba(0, 0, 0, 0.2), 0 2px 6px rgba(0,0,0,0.15);
}
.jqx-tooltip-material, .jqx-tooltip-material.jqx-popup-material, .jqx-tooltip-material .jqx-fill-state-normal-material {
    background: #6F6F6F;
    border-color: #6F6F6F;
    box-shadow:none;
    color: #fff;
}
.jqx-docking-material .jqx-window-material {
    box-shadow: none;
}

.jqx-docking-panel-material .jqx-window-material {
    box-shadow: none;
}

.jqx-checkbox-material {
    line-height:20px;
    overflow: visible;
}
.jqx-radiobutton-material {
    overflow: visible;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    background-repeat: no-repeat;
    background: none;
    line-height:20px;
}

.jqx-radiobutton-material-material, .jqx-radiobutton-hover-material {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    background-repeat: no-repeat;
    transition: background-color ease-in .3s;
}

.jqx-radiobutton-check-checked-material {
    filter: none;
    background: #fff;
    background-repeat: no-repeat;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-material {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-radiobutton-check-indeterminate-disabled-material {
    filter: none;
    background: #999;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.jqx-checkbox-default-material,
.jqx-radiobutton-default-material
 {
    border-width: 1px;
    border-color: #E0E0E0;
    background-color: #fff;
    overflow: visible;
}

.jqx-tree-material .jqx-checkbox-material .jqx-checkbox-default-material,
.jqx-checkbox-material[checked] .jqx-checkbox-default-material,
.jqx-tree-grid-checkbox[checked].jqx-checkbox-default-material,
.jqx-radiobutton-material[checked] .jqx-radiobutton-default-material
 {
    background-color: #0077BE;
    border-color: #0077BE;
}

.jqx-checkbox-check-checked-material {
    background: transparent url(images/material_check_white.png) center center no-repeat;
}
.jqx-checkbox-check-indeterminate-material {
    width:14px !important;
    height:14px !important;
    position:relative;
    top: 1px;
    left: 1px;
    background: white;
}
.jqx-tree-material .jqx-checkbox-check-indeterminate-material {
    width:12px !important;
    height:12px !important;
    top: 2px;
    left:2px;
}

.jqx-checkbox-hover-material,
.jqx-radiobutton-hover-material {
    background-color: #0077BE;
    border-color: #0077BE;
}


.jqx-slider-slider-material {
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease;
}

.jqx-slider-slider-material:active {
    transform: scale(1.2);
    box-shadow: rgba(0,0,0,0.3) 0 0 10px;
}
.jqx-slider-material[discrete] .jqx-slider-slider-material:active
 {
    transform: scaleX(0);

}
.jqx-slider-slider-horizontal-material {
    background: #0077BE;
}
.jqx-slider-slider-vertical-material {
    background: #0077BE;
}
.jqx-slider-tooltip-material {
    width: 25px;
    height: 25px;
    transform-origin: 50% 100%;
    border-radius: 50%;
    transform: scale(0) rotate(45deg);
    padding:0px;
    background: transparent !important;
}
.jqx-slider-tooltip-material.init {
     transform: scale(1) rotate(45deg);
}
.jqx-slider-tooltip-material.hide {
     transition: transform 0.2s ease;
     transform-origin:50% 100%;
     transform: scale(0) rotate(45deg);
}
.jqx-slider-tooltip-material.show {
     transition: transform 0.2s ease;
     transform: scale(1) rotate(45deg);
}


.jqx-slider-tooltip-material .jqx-tooltip-arrow-t-b,
.jqx-slider-tooltip-material .jqx-tooltip-arrow-l-r {
    display:none;
    visibility:hidden;
}

.jqx-slider-tooltip-material, .jqx-slider-tooltip-material .jqx-fill-state-normal-material {
    border-radius: 15px 15px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0077BE;
    color: #fff;
    font-size:11px;
}
.jqx-slider-tooltip-material.far, .jqx-slider-tooltip-material.far .jqx-fill-state-normal-material {
   border-radius: 0px 15px 15px 15px;
}
.jqx-slider-tooltip-material.vertical, .jqx-slider-tooltip-material.vertical .jqx-fill-state-normal-material {
   border-radius: 15px 0px 15px 15px;
}
.jqx-slider-tooltip-material.vertical.far, .jqx-slider-tooltip-material.vertical.far .jqx-fill-state-normal-material {
   border-radius: 15px 15px 15px 0px;
}
.jqx-slider-tooltip-material {
    background:transparent;
    border:none !important;
    box-shadow:none;
}
.jqx-slider-tooltip-material .jqx-tooltip-main-material {
    top: -7px;
    right: 11px;
}
.jqx-slider-tooltip-material.far .jqx-tooltip-main-material {
    top: 3px;
    right: 4px;
}
.jqx-slider-tooltip-material.vertical .jqx-tooltip-main-material {
    top: -3px;
    right: 3px;
}
.jqx-slider-tooltip-material .jqx-tooltip-text {
    background: transparent;
    border:none;
    padding: 0px;
    overflow:visible;
}
.jqx-slider-tooltip-material .jqx-tooltip-text>span {
     transform: rotate(-45deg);
}
.jqx-slider-tooltip-material.range {
    width: 35px;
    height:35px;
}

.jqx-slider-rangebar-material {
    border-color: #0077BE;
    background: #0077BE;
}

.jqx-slider-track-horizontal-material, .jqx-slider-track-vertical-material {
    border-color: #E0E0E0;
    background: #f0f0f0;
}

.jqx-slider-button-material {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
.jqx-slider-button-material.jqx-fill-state-normal-material,
.jqx-slider-button-material.jqx-fill-state-hover-material,
.jqx-slider-button-material.jqx-fill-state-pressed-material
{
    background: transparent !important;
}

.jqx-listitem-state-hover-material,
.jqx-listitem-state-selected-material,
.jqx-listitem-state-normal-material {
    padding: 5px;
    margin:0px;
}

.jqx-scheduler-edit-dialog-label-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;

}
.jqx-scheduler-edit-dialog-field-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.jqx-scheduler-edit-dialog-label-rtl-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.jqx-scheduler-edit-dialog-field-rtl-material {
  line-height: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}

/*applied to a list item when the item is selected.*/
.jqx-listitem-state-hover-material, .jqx-menu-item-hover-material, .jqx-tree-item-hover-material, .jqx-calendar-cell-hover-material, .jqx-grid-cell-hover-material,
.jqx-menu-vertical-material .jqx-menu-item-top-hover-material, .jqx-input-popup-material .jqx-fill-state-hover-material,
.jqx-input-popup-material .jqx-fill-state-pressed-material {
    color: #333 !important;
    border-color: #F5F5F5;
    text-decoration: none;
    background-color: #F5F5F5;
    background-repeat: repeat-x;
    outline: 0;
    background: #F5F5F5; /* Old browsers */
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-position: 0 0;
}

.jqx-scheduler-cell-hover-material {
    border-color: #F5F5F5 !important;
    background: #F5F5F5 !important;
}

.jqx-listitem-state-selected-material, .jqx-menu-item-selected-material, .jqx-tree-item-selected-material, .jqx-calendar-cell-selected-material, .jqx-grid-cell-selected-material,
.jqx-menu-vertical-material .jqx-menu-item-top-selected-material, .jqx-grid-selectionarea-material, .jqx-input-button-header-material, .jqx-input-button-innerHeader-material {
    color: #0077BE !important;
    border-color: #E1F5FE !important;
    background: #E1F5FE; /* Old browsers */
    box-shadow: none;
}


.jqx-grid-cell-material .jqx-button-material, .jqx-grid-cell-material .jqx-button-material.jqx-fill-state-hover-material, .jqx-grid-cell-material .jqx-button-material.jqx-fill-state-pressed-material {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.jqx-popup-material {
    border: 1px solid #E0E0E0;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.jqx-grid-column-sortascbutton-material, .jqx-expander-arrow-bottom-material, .jqx-window-collapse-button-material, .jqx-menu-item-arrow-up-material, .jqx-menu-item-arrow-up-selected-material, .jqx-menu-item-arrow-top-up-material, .jqx-icon-arrow-up-material, .jqx-icon-arrow-up-hover-material, .jqx-icon-arrow-up-selected-material {
    background-image: url('images/material-icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-material .jqx-grid-group-expand-material, .jqx-grid-group-expand-material, .jqx-grid-column-menubutton-material, .jqx-grid-column-sortdescbutton-material, .jqx-expander-arrow-top-material, .jqx-window-collapse-button-collapsed-material, .jqx-menu-item-arrow-down-material, .jqx-menu-item-arrow-down-selected-material, .jqx-menu-item-arrow-down-material, .jqx-icon-arrow-down-material, .jqx-icon-arrow-down-hover-material, .jqx-icon-arrow-down-selected-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-arrow-left-material, .jqx-menu-item-arrow-left-selected-material, .jqx-menu-item-arrow-top-left, .jqx-icon-arrow-left-material, .jqx-icon-arrow-down-left-material, .jqx-icon-arrow-left-selected-material {
    background-image: url('images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-widget-material .jqx-grid-group-collapse-material, .jqx-grid-group-collapse-material, .jqx-tabs-arrow-right-material, .jqx-menu-item-arrow-right-selected-material, .jqx-menu-item-arrow-top-right-material, .jqx-icon-arrow-right-material, .jqx-icon-arrow-right-hover-material, .jqx-icon-arrow-right-selected-material {
    background-image: url('images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tree-item-arrow-collapse-rtl-material, .jqx-tree-item-arrow-collapse-hover-rtl-material {
    background-image: url(images/material-icon-left.png);
}

.jqx-menu-item-arrow-left-selected-material {
    background-image: url('images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-menu-item-arrow-right-selected-material {
    background-image: url('images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-input-button-content-material {
    font-size: 10px;
}

.jqx-widget .jqx-grid-column-header-cell-material {
    padding-top: 8px;
    padding-bottom: 8px;
    height:30px;
}

.jqx-widget .jqx-grid-row-cell-material {
    padding-top: 8px;
    padding-bottom: 8px;
    height:30px;
}

.jqx-widget .jqx-grid-cell, .jqx-widget .jqx-grid-column-header, .jqx-widget .jqx-grid-group-cell {
    border-color: #E0E0E0;
}

.jqx-combobox-material .jqx-icon-close-material {
    background-image:url(images/close_white.png)
}
.jqx-combobox-material, .jqx-input-material {
    border-color: #E0E0E0;
    color: #555555;
    background-color: #ffffff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.jqx-combobox-content-material,
.jqx-datetimeinput-content-material
 {
    border-color: transparent;
}
.jqx-combobox-arrow-normal-material {
    background: #fff;
    border-color: transparent;
}

.jqx-combobox-content-focus-material,
.jqx-combobox-state-focus-material,
.jqx-numberinput-focus-material {
    outline: none;
}

.jqx-input-group-material {
    position: relative;
    display: inline-block;
    overflow: visible;
    border: none;
    box-shadow: none;
}

    .jqx-input-group-material input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .jqx-input-group-material textarea {
        width: 100%;
        height: 100%;
        outline: none;
        resize: none;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom-color: #e0e0e0;
    }
.jqx-numberinput-material,
.jqx-maskedinput-material
 {
    position:relative;
}
.jqx-numberinput-material input {
    height:100% !important;
}

.jqx-input-material.jqx-validator-error-element {
    border-color: transparent !important;
    border-bottom: 1px solid #df2227 !important;
}
.jqx-input-material input,
.jqx-dropdownlist-state-normal-material,
.jqx-combobox-state-normal-material,
.jqx-datetimeinput-material,
.jqx-numberinput-material,
.jqx-maskedinput-material
 {
    background: #fff;
    border-color: #fff;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #E0E0E0;
    outline: none;
}
.jqx-datetimeinput-material .jqx-action-button-material,
.jqx-datetimeinput-material .jqx-action-button-rtl-material
 {
    background-color: transparent;
    border-color: transparent;
}
    .jqx-datetimeinput-material, .jqx-datetimeinput-material > div,
    .jqx-numberinput-material, .jqx-numberinput-material > div,
    .jqx-maskedinput-material, .jqx-maskedinput-material > div,
    .jqx-dropdownlist-state-normal-material, .jqx-dropdownlist-state-normal-material > div, .jqx-dropdownlist-state-normal-material > div > div,
    .jqx-combobox-state-normal-material, .jqx-combobox-state-normal-material > div, .jqx-combobox-state-normal-material > div > div {
        overflow: visible !important;
    }

    .jqx-input-material input:focus {
        border-radius: 0;
        box-shadow: none;
    }

.jqx-input-material input, input[type="text"].jqx-input-material, input[type="password"].jqx-input-material, input[type="text"].jqx-widget-content-material, input[type="textarea"].jqx-widget-content-material, textarea.jqx-input-material {
    font-size: 14px;
    resize: none;
    background: #fff;
    border: none;
    border-radius: 0;
    box-sizing:border-box;
    box-shadow: none;
    border-bottom: 1px solid #E0E0E0;
}
.jqx-input-label {
    visibility:inherit;
}
.jqx-input-bar{
    visibility:inherit;
}
input:focus ~ .jqx-input-label-material,
textarea:focus ~ .jqx-input-label-material,
.jqx-input-widget-material[hint=true] .jqx-input-label,
.jqx-text-area-material[hint=true] .jqx-input-label,
.jqx-dropdownlist-state-selected-material .jqx-input-label,
.jqx-dropdownlist-state-normal-material[hint=true] .jqx-input-label,
.jqx-combobox-state-normal-material[hint=true] .jqx-input-label,
.jqx-combobox-material .jqx-input-label.focused,
.jqx-dropdownlist-material .jqx-input-label.focused,
.jqx-datetimeinput-material[hint=true] .jqx-input-label,
.jqx-maskedinput-material[hint=true] .jqx-input-label,
.jqx-numberinput-material[hint=true] .jqx-input-label,
.jqx-formattedinput-material[hint=true] .jqx-input-label
 {
    top: -15px;
    font-size: 12px;
    color: #0077BE;
}
.jqx-dropdownlist-material[default-placeholder="true"] .jqx-input-label {
    visibility: hidden;
}


input:focus ~ .jqx-input-bar:before,
textarea:focus ~ .jqx-input-bar:before,
.jqx-dropdownlist-state-selected-material .jqx-input-bar:before,
.jqx-dropdownlist-material .jqx-input-bar.focused:before,
.jqx-combobox-material .jqx-input-bar.focused:before,
.jqx-complex-input-group-material .jqx-input-bar.focused::before,
.jqx-combobox-state-selected-material .jqx-input-bar:before {
    width: 100%;
}
.jqx-complex-input-group-material .jqx-fill-state-normal-material {
    border-color: #fafafa;
}


.jqx-input-widget-material.jqx-rtl > input {
    direction: rtl
}

.jqx-input-label-material {
    color: #E0E0E0;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 2px;
    top:10px;
    top: calc(50% - 7px);
    transition: 300ms ease all;
}
.jqx-input-label.initial {
    transition: none;
}
.jqx-input-bar-material {
    position: relative;
    display: block;
    z-index:1;
}

    .jqx-input-bar-material:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        background: #0077BE;
        transition: 300ms ease all;
        left: 0%;
    }
.jqx-formatted-input-spin-button-material, .jqx-input-group-addon-material {
    border-color: #fff;
    background: #fff;
}
.jqx-dropdownlist-state-selected-material,
.jqx-combobox-state-selected-material {
    color: #0077BE;
}


.jqx-dropdownlist-state-normal-material .jqx-icon-arrow-down-material,
.jqx-combobox-state-normal-material .jqx-icon-arrow-down-material,
.sorticon.descending .jqx-grid-column-sorticon-material,
.jqx-tree-item-arrow-expand-material,
 .jqx-expander-header-material .jqx-icon-arrow-down
 {
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
}
.jqx-expander-header-material .jqx-icon-arrow-up {
   transform: rotate(180deg);
   transition: transform 0.2s ease-out;
    background-image: url('images/material-icon-down.png');
}

.jqx-tree-item-arrow-collapse-material
{
    transform: rotate(-90deg);
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s ease-out;
}
.jqx-dropdownlist-state-selected-material .jqx-icon-arrow-down-material,
.jqx-combobox-state-selected-material .jqx-icon-arrow-down-material,
.sorticon.ascending .jqx-grid-column-sorticon-material
 {
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
    left: -1px;
}
.jqx-combobox-state-selected-material .jqx-icon-arrow-down-material{
    left:-1px;
}
.jqx-listbox-container {
    margin-top: 1px;
}

input[type="text"].jqx-input-material:-moz-placeholder, input[type="text"].jqx-widget-content-material:-moz-placeholder, input[type="textarea"].jqx-widget-content-material:-moz-placeholder, textarea.jqx-input-material:-moz-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-material:-webkit-input-placeholder, input[type="text"].jqx-widget-content-material:-webkit-input-placeholder, input[type="textarea"].jqx-widget-content-material:-webkit-input-placeholder, textarea.jqx-input-material:-webkit-input-placeholder {
    color: #999999;
}

input[type="text"].jqx-input-material:-ms-input-placeholder, input[type="text"].jqx-widget-content-material:-ms-input-placeholder, input[type="textarea"].jqx-widget-content-material:-ms-input-placeholder, textarea.jqx-input-material:-ms-input-placeholder {
    color: #999999;
}

.jqx-combobox-content-focus-material, .jqx-combobox-state-focus-material, .jqx-fill-state-focus-material,
.jqx-numberinput-focus-material {
    outline: none;
}

.jqx-popup-material.jqx-fill-state-focus-material {
    outline: none;
    border-color: #E0E0E0 !important;
}

.jqx-datetimeinput-content, .jqx-datetimeinput-container {
    overflow: visible !important;
}
.jqx-text-area-material, .jqx-text-area-material > div {
    overflow:visible !important;
}
.jqx-text-area-element-material {
   box-sizing: border-box;
}
.jqx-pivotgrid-content-wrapper.jqx-fill-state-normal-material {
    border-color: #E0E0E0;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-expand-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-collapse-material {
    background-image: url('images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-collapse-rtl-material {
    background-image: url('images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-expand-rtl-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-tabs-title-selected-top-material, .jqx-tabs-selection-tracker-top-material {
    border-color: transparent;
    filter: none;
    background: #fff;
    color: #333;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.jqx-grid-cell-filter-row-material {
    background-color: #fafafa;
}

.jqx-tabs-title-material, .jqx-ribbon-item-material {
    color: #333;
}
.jqx-tabs-title-selected-bottom-material,
.jqx-tabs-title-selected-top-material
 {
    color: #0077BE;
    font-weight:500;
    padding-top:5px;
    padding-bottom:5px;
}
.jqx-tabs-title.jqx-fill-state-hover-material {
    border-color: transparent;
}
.jqx-ribbon-item-material {
    cursor: pointer;
}
.jqx-ribbon-item-selected-material {
    color: #0077BE;
    font-weight:500;
    border-color: transparent;
}

.jqx-ribbon-item-hover-material {
    background: transparent;
}

.jqx-ribbon-header-top-material {
    border-color: transparent;
    border-bottom-color: #E0E0E0;
}

.jqx-ribbon-header-bottom-material {
    border-color: transparent;
    border-top-color: #E0E0E0;
}

.jqx-ribbon-header-right-material {
    border-color: transparent;
    border-left-color:#E0E0E0;
}

.jqx-ribbon-header-left-material {
    border-color: transparent;
    border-right-color:#E0E0E0;
}

.jqx-tabs-title-selected-bottom-material, .jqx-tabs-selection-tracker-bottom-material {
    border-color: transparent;
    border-top: 1px solid #fff;
    filter: none;
    background: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.jqx-tabs-material, .jqx-ribbon-material {
    border-color: transparent;
}

.jqx-tabs-header-material, .jqx-ribbon-header-material {
    background: transparent;
}
.jqx-tabs-position-bottom .jqx-tabs-header-material {
    border-color: transparent;
}
.jqx-layout-material .jqx-tabs-header-material, .jqx-layout-material .jqx-ribbon-header-material {
    background: #fff;
    border-color: #E0E0E0;
}
.jqx-tabs-title-bottom {
    border-color: transparent;
}
.jqx-tabs-title-hover-top-material, .jqx-tabs-title-hover-bottom-material, .jqx-tabs-header-material {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent;
}

.jqx-tabs-content-material {
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    border-top-color: transparent;
    padding:5px;
}
.jqx-tabs-bar-material {
    position: absolute;
    bottom: 0;
    background: #0077BE;
    height: 2px;
    z-index:10;
    transition: .5s cubic-bezier(.35,0,.25,1);
}
.jqx-tabs-bar-material.vertical {
    width: 2px;
}
.jqx-tabs-position-bottom .jqx-tabs-bar-material {
    top: 0;
}


.jqx-layout-material {
    background-color: #cccccc;
}

.jqx-kanban-column-header-collapsed-material {
    background: -moz-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ff3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(248,248,248,1)), color-stop(100%, rgba(234,234,234,1))); /* safari4+,chrome */
    background: -webkit-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* opera 11.10+ */
    background: -ms-linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* ie10+ */
    background: linear-gradient(90deg, rgba(248,248,248,1) 0%, rgba(234,234,234,1) 100%); /* w3c */
}


.jqx-calendar-cell-material {
    border-radius: 50%;
    font-size:12px;
}
.jqx-calendar-cell-year-material,
.jqx-calendar-cell-decade-material {
    border-radius: 25%;
}

.jqx-calendar-title-content-material {
    font-weight:bold;
}
.jqx-calendar-column-cell-material {
    color: rgba(0,0,0,.38);
    font-size:12px;
}
.jqx-grid-column-menubutton-material {
    background-image: url('images/material-icon-down.png');
}

.jqx-tabs-close-button-material {
    background-image: url(images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-close-button-selected-material {
    background-image: url(images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-tabs-close-button-hover-material {
    background-image: url(images/close.png);
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-scrollbar-button-state-pressed-material .jqx-icon-arrow-up-selected-material {
    background-image: url('images/material-icon-up.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-scrollbar-button-state-pressed-material .jqx-icon-arrow-down-selected-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-scrollbar-button-state-pressed-material .jqx-icon-arrow-left-selected-material {
    background-image: url('images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-scrollbar-button-state-pressed-material .jqx-icon-arrow-right-selected-material {
    background-image: url('images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-expand-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-collapse-material {
    background-image: url('images/material-icon-right.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-collapse-rtl-material {
    background-image: url('images/material-icon-left.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-cell-material.jqx-grid-cell-selected-material > .jqx-grid-group-expand-rtl-material {
    background-image: url('images/material-icon-down.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-grid-group-collapse-material {
    background-image: url(images/material-icon-right.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-grid-group-collapse-rtl-material {
    padding-right: 0px;
    background-image: url(images/material-icon-left.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-grid-group-expand-material, .jqx-grid-group-expand-rtl-material {
    padding-right: 0px;
    background-image: url(images/material-icon-down.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.jqx-icon-arrow-first-material {
    background-image: url('images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-material {
    background-image: url('images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-first-hover-material {
    background-image: url('images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-hover-material {
    background-image: url('images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-first-selected-material {
    background-image: url('images/material-icon-first.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-icon-arrow-last-selected-material {
    background-image: url('images/material-icon-last.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-fill-state-pressed-material .jqx-icon-arrow-first-selected-material {
    background-image: url('images/material-icon-first-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-fill-state-pressed-material .jqx-icon-arrow-last-selected-material {
    background-image: url('images/material-icon-last-white.png');
    background-repeat: no-repeat;
    background-position: center;
}
.jqx-fill-state-pressed-material .jqx-icon-arrow-left-selected-material {
    background-image: url('images/material-icon-left-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-fill-state-pressed-material .jqx-icon-arrow-right-selected-material {
    background-image: url('images/material-icon-right-white.png');
    background-repeat: no-repeat;
    background-position: center;
}

.jqx-primary .jqx-icon-arrow-down-material, .jqx-warning .jqx-icon-arrow-down-material, .jqx-danger .jqx-icon-arrow-down-material, .jqx-success .jqx-icon-arrow-down-material, .jqx-info .jqx-icon-arrow-down-material {
    background-image: url('images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-down-selected-material, .jqx-warning .jqx-icon-arrow-down-selected-material, .jqx-danger .jqx-icon-arrow-down-selected-material, .jqx-success .jqx-icon-arrow-down-selected-material, .jqx-info .jqx-icon-arrow-down-selected-material {
    background-image: url('images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-down-hover-material, .jqx-warning .jqx-icon-arrow-down-hover-material, .jqx-danger .jqx-icon-arrow-down-hover-material, .jqx-success .jqx-icon-arrow-down-hover-material, .jqx-info .jqx-icon-arrow-down-hover-material {
    background-image: url('images/material-icon-down.png');
}

.jqx-primary .jqx-icon-arrow-up-material, .jqx-warning .jqx-icon-arrow-up-material, .jqx-danger .jqx-icon-arrow-up-material, .jqx-success .jqx-icon-arrow-up-material, .jqx-info .jqx-icon-arrow-up-material {
    background-image: url('images/material-icon-up.png');
}

.jqx-primary .jqx-icon-arrow-up-selected-material, .jqx-warning .jqx-icon-arrow-up-selected-material, .jqx-danger .jqx-icon-arrow-up-selected-material, .jqx-success .jqx-icon-arrow-up-selected-material, .jqx-info .jqx-icon-arrow-up-selected-material {
    background-image: url('images/material-icon-up.png');
}

.jqx-primary .jqx-icon-arrow-up-hover-material, .jqx-warning .jqx-icon-arrow-up-hover-material, .jqx-danger .jqx-icon-arrow-up-hover-material, .jqx-success .jqx-icon-arrow-up-hover-material, .jqx-info .jqx-icon-arrow-up-hover-material {
    background-image: url('images/material-icon-up.png');
}


.jqx-primary .jqx-icon-arrow-left-material, .jqx-warning .jqx-icon-arrow-left-material, .jqx-danger .jqx-icon-arrow-left-material, .jqx-success .jqx-icon-arrow-left-material, .jqx-info .jqx-icon-arrow-left-material {
    background-image: url('images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-left-selected-material, .jqx-warning .jqx-icon-arrow-left-selected-material, .jqx-danger .jqx-icon-arrow-left-selected-material, .jqx-success .jqx-icon-arrow-left-selected-material, .jqx-info .jqx-icon-arrow-left-selected-material {
    background-image: url('images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-left-hover-material, .jqx-warning .jqx-icon-arrow-left-hover-material, .jqx-danger .jqx-icon-arrow-left-hover-material, .jqx-success .jqx-icon-arrow-left-hover-material, .jqx-info .jqx-icon-arrow-left-hover-material {
    background-image: url('images/material-icon-left.png');
}

.jqx-primary .jqx-icon-arrow-right-material, .jqx-warning .jqx-icon-arrow-right-material, .jqx-danger .jqx-icon-arrow-right-material, .jqx-success .jqx-icon-arrow-right-material, .jqx-info .jqx-icon-arrow-right-material {
    background-image: url('images/material-icon-right.png');
}

.jqx-primary .jqx-icon-arrow-right-selected-material, .jqx-warning .jqx-icon-arrow-right-selected-material, .jqx-danger .jqx-icon-arrow-right-selected-material, .jqx-success .jqx-icon-arrow-right-selected-material, .jqx-info .jqx-icon-arrow-right-selected-material {
    background-image: url('images/material-icon-right.png');
}

.jqx-primary .jqx-icon-arrow-right-hover-material, .jqx-warning .jqx-icon-arrow-right-hover-material, .jqx-danger .jqx-icon-arrow-right-hover-material, .jqx-success .jqx-icon-arrow-right-hover-material, .jqx-info .jqx-icon-arrow-right-hover-material {
    background-image: url('images/material-icon-right.png');
}


/* Ripple effect */
.ripple {
    position: relative;
    transform: translate3d(0, 0, 0);
    overflow:hidden;
}

.ink {
    display: block;
    position: absolute;
    pointer-events: none;
    border-radius: 0%;
    transform: scaleX(0);
    background: rgba(0,119,190,0.5);
    opacity: 0.25;
}


.outlined .ink, .flat .ink {
    background: rgba(0,119,190,0.5);
    overflow:hidden;
}

.ink.animate {
    animation: ripple .7s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.jqx-checkbox-material .ripple,
.jqx-radiobutton-material .ripple
 {
    overflow:visible;
}
.jqx-checkbox-material .ink,
.jqx-radiobutton-material .ink
 {
    transform: scale(0);
    background: #0077BE;
    border-radius: 50%;
}
.jqx-checkbox-material .ink.animate,
.jqx-radiobutton-material .ink.animate
 {
    animation: checkRipple 0.3s ease;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.jqx-checkbox-material .ink.active,
.jqx-radiobutton-material .ink.active
 {
    opacity: 0.2;
    transform: scale(2);
}
.jqx-checkbox-default-material.active .ink,
.jqx-radiobutton-default-material.active .ink
 {
    opacity: 0.2;
    transform: scale(2);
}
/* Ripple effect */
.buttonRipple {
  background-position: center;
  transition: background 0.8s;
}
.buttonRipple:hover {
  background: #0077BE radial-gradient(circle, transparent 1%, #0077BE 1%) center/15000%;
}
.buttonRipple:active {
  background-color: #B3E5FC;
  background-size: 100%;
  transition: background 0s;
}
.buttonRipple:active:not(:hover) {
      color: #0077BE;
}
@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(5);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
}
@keyframes checkRipple {
    100% {
        opacity: 0.2;
        transform: scale(2);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
}






.jqx-fill-state-pressed-material .jqx-icon-delete-material
{
    background-image: url('images/icon-delete-white.png');
}
.jqx-fill-state-pressed-material .jqx-icon-edit-material
{
    background-image: url('images/icon-edit-white.png');
}
.jqx-fill-state-pressed-material .jqx-icon-save-material
{
    background-image: url('images/icon-save-white.png');
}
.jqx-fill-state-pressed-material .jqx-icon-cancel-material
{
    background-image: url('images/icon-cancel-white.png');
}
.jqx-fill-state-pressed-material .jqx-icon-search-material
{
    background-image: url(images/search_white.png);
}
.jqx-fill-state-pressed-material .jqx-icon-plus-material
{
    background-image: url(images/plus_white.png);
}
.jqx-fill-state-pressed-material .jqx-menu-minimized-button-material {
   background-image: url('images/icon-menu-minimized-white.png');
}
.jqx-fill-state-hover-material .jqx-editor-toolbar-icon-material,
.jqx-fill-state-pressed-material .jqx-editor-toolbar-icon-material {
    background: url('images/html_editor_white.png') no-repeat;
}

.jqx-fill-state-hover-material .jqx-editor-toolbar-icon-fontsize-material,
.jqx-fill-state-pressed-material .jqx-editor-toolbar-icon-fontsize-material,
.jqx-fill-state-hover-material .jqx-editor-toolbar-icon-forecolor-material,
.jqx-fill-state-pressed-material .jqx-editor-toolbar-icon-forecolor-material
{
        background: url('images/html_editor.png') no-repeat;
}

.jqx-editor-toolbar-button-material{
    border-color: #ddd;
    box-shadow: none !important;
	color: #333;
}

jqx-editor-toolbar-icon-fontsize-material,
jqx-editor-toolbar-icon-fontcolor-material {

}
.jqx-input-group-material.jqx-fill-state-disabled {
	pointer-events: none;
}

/*applied to the timepicker*/
.jqx-needle-central-circle-material {
	fill: rgb(0, 119, 190);
}
.jqx-needle-material {
	fill: rgb(0, 119, 190);
}
.jqx-time-picker .jqx-header .jqx-selected-material:focus {
    outline: 2px solid rgba(0, 119, 190, 0.5);
	box-shadow: 0px 0px 4px 2px rgba(0, 119, 190, 0.125);
}
.jqx-svg-picker-material:focus {
	border: 1px solid rgb(0, 119, 190) !important;
}