.div-bin {
    width: 100%;
    display: block;

    .div-bin-input {
        width: 90%;
        display: inline-block;
    }

    .div-bin-btn {
        width: 10%;
        display: inline-block;
        text-align: center;
        font-size: 14px;
    }
}

.alert-heading {
    font-size: 14px;
}

.bin-error-list {
    font-size: 12px;
    list-style-type: initial;
    padding-left: inherit;
}

.return-restock-table {
    .sc-dmctIk {
        overflow-x: inherit !important;
        overflow-y: inherit !important;
    }
}