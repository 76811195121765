@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

@import "~react-toastify/dist/ReactToastify.css";
@import "~react-input-range/lib/css/index.css";

.active-invoice {
  border: 1px solid #4caf50;
}

.active-status {
  background: white;
}

.slick-arrow .slick-initialized {
  display: flex !important;
}
.slick-track {
  margin-left: 0 !important;
}
.invalid-feedback {
  color: red !important;
}

.validation-msg {
  color: red;
}

.jqx-grid-column-header,
.jqx-grid-statusbar-material {
  z-index: 0 !important;
}

.jqx-widget-content-material {
  z-index: 1 !important;
}
.scale-image-8 {
  transform: scale(0.8);
}
.scale-image-6 {
  transform: scale(0.6);
}

.jqx-grid-column-header {
  border-left: 1px solid #e0e0e0 !important;
}

.css-2613qy-menu {
  z-index: 1000000 !important;
  background-color: red;
}

.css-xn1lin {
  z-index: 1000000 !important;
  background-color: red;
}

.my-custom-react-select__menu {
  z-index: 2;
}

.my-custom-react-select__control {
  border-width: 1px !important;
  border-color: #cbd5e0 !important;
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
}

//.jqx-grid-cell-material{
//  z-index: 79 !important;
//}

.my-custom-react-select__control--is-focused {
  border-color: #746df7 !important;
  box-shadow: none !important;
}

.my-custom-react-select__indicator-separator {
  display: none;
}

.pagination {
  display: flex;
  float: right;
}

.pagination-active {
  background-color: #0c5460;
  color: white;
}
.ck-editor__editable {
  min-height: 100px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
}
.ck-editor__main ul {
  list-style-type: disc;
  padding: 10px 0 !important;
  padding-left: 20px !important;
}

.pagination a.active {
  background-color: #4caf50;
  color: white;
  border: 1px solid #4caf50;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.react-tagsinput-tag {
  width: auto !important;
}

.tag-input-custom {
  padding: 2px !important;
  height: auto !important;
}

.image-crop-center {
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 25px;
}

.rowColor {
  background-color: #e5e5e5 !important;
}

.variant-image:hover,
.active-variant-image {
  border: 1px solid #4caf50;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select2-filter {
  border: none;
}

.badge-green {
  background-color: #bef781 !important;
}

.badge-red {
  background-color: #ffd0d0 !important;
}

.badge-blue {
  background-color: #aab7f9 !important;
}

.scrollarea {
  min-height: 85vh !important;
}
.input-with-icon {
  div {
    .form-group {
      position: relative;

      input {
        margin-bottom: 15px;
        // border: none;
        //   box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
        //height: 50px;
        padding-left: 30px;
      }

      i {
        position: absolute;
        top: 10px;
        right: 30px;
        color: $dark-gray;
      }
    }
  }
}

.a-text-color {
  color: blue;
  cursor: pointer;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.jqx-delete-button-div {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  position: absolute;
}

.ril__captionContent {
  width: 100%;
}

.nav-item .active {
  color: #0c63e4 !important;
}
.border-tab {
  cursor: pointer;
}
.text-limit {
  font-size: 12px;
  color: green;
}

.lightbox-div div {
  z-index: 100;
}
.product-price {
  font-size: 15px !important;
}

//.checkbox label::before {
//  content: "";
//  display: inline-block;
//  position: absolute;
//  width: 19px;
//  height: 19px;
//  left: 0;
//  margin-left: -16px;
//  border: 1px solid #7c5b5b;
//  border-radius: 3px;
//  background-color: #304f7e;
//  color: white;
//  font-weight: bolder;
//  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
//}

.iconscontainer {
  display: none !important;
}

.notification-dropdown {
  width: 900% !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:not(.profile-dropdown) li:first-child {
  //background-color: white !important;
}
.image-color:hover,
.active-image-color {
  border: 3px solid #4caf50;
  cursor: pointer;
}
.card-height-3 {
  height: 420px;
}
.card-height-2 {
  height: 280px;
}
@media only screen and (max-width: 1400px) {
  body {
    font-size: 12px;
  }
  .project-list .border-tab.nav-tabs .nav-item .nav-link {
    font-size: 12px;
  }
  .card-height-3 {
    height: 250px;
  }
  .card-height-2 {
    height: 160px;
  }
  .product-details {
    zoom: 0.7;
  }
  .ribbon {
    padding: 0 15px;
    height: 20px;
    line-height: 20px;
  }
}

.list-group li a {
  cursor: pointer;
}
.active-colum {
  border: 3px solid black;
}
.rs-picker-toggle .rs-btn-lg .rs-picker-toggle-textbox .rs-picker .rs-picker-textbox {
  padding-left: 85px !important;
}
.log-activity-image {
  width: 30px;
  height: 30px;
}
.log-activity-image {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  background-color: #000;
  display: inline-block;
  box-sizing: border-box;
}
.log-activity-image div {
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: inherit;
  font-weight: inherit;
}

.bin-qty {
  display: inline-block;
  padding: 8px 10px;
  background-color: #d1e7dd;
  margin-right: 10px;
  font-weight: 600;
  min-width: 100px;
  text-align: center;
}

.dotted-left,
.dotted-right {
  padding: 1px 0.5em;
  background: #fff;
  float: right;
}

.dotted-left {
  float: left;
  clear: both;
}

.dotted {
  border-bottom: 1px dotted grey;
  margin-bottom: 2px;
}
.dotted-div {
  height: 1em;
}

.jqx-grid .editable-column {
  border-bottom: 3px dotted grey !important;
}

div[id^="JqxGridjq"] {
  transform: translate3d(0, 0, 0);
}
//div[id^="JqxGridjq"]:has(div[id^="horizontalScrollBar"]) {
//  /* Styles for #parent when #child exists */
//  border-color: red !important;
//}

//div[id^="horizontalScrollBar"] {
//  visibility:hidden  !important;
//}
