.add_brand_btn{
    color: #7366ff;
    border:1px solid #b9b1f8 !important;
    background-color:white;
    height:100px;
    width:13vw;
    transition: 0.3s;
    cursor: pointer;
}
.add_brand_btn:hover{
    color: white;
    background-color: #7366ff;
}

.sc-cCcXHH > svg{
    color: #7366ff !important;
}